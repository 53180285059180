import { useEffect, useRef } from "react";
import "./Landing.scss";

// component imports
import Header from "../../components/Header/Header";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/SplitText";

const Landing = () => {
  let isMounted = true;
  const landingRef = useRef(null);

  // intro animation
  useGSAP(() => {
    // make sure page is loaded before animating
    if (!isMounted) return;
    setTimeout(() => {
      const isMobile = window.innerWidth < 768;
      const split = new SplitText(".name", {
        type: "chars",
        charsClass: "titleChar",
        position: "absolute",
      });

      gsap.set(".landing-content", { opacity: 1, rotate: -25 });

      let tl = gsap.timeline({
        defaults: {
          duration: 2,
          ease: "power4",
        },
      });
      tl.from(".names", {
        opacity: 0,
        x: function (i) {
          if (i % 2 === 0) {
            return 1000;
          }
          return -1000;
        },
        stagger: 0.15,
      });

      const nameTl = gsap.timeline({ repeat: -1 });
      nameTl
        .to(".names", {
          y: isMobile ? -70 : -102,
          //y: -102,
          duration: 3,
          ease: "none",
        })
        .from(
          ".nameEndRed .titleChar",
          {
            y: isMobile ? 80 : 120,
            //y: 120,
            duration: 1,
            ease: "power4",
            stagger: 0.05,
          },
          1
        )
        .from(
          ".nameEndBlue .titleChar",
          {
            y: isMobile ? 80 : 120,
            //y: 120,
            duration: 1,
            ease: "power4",
            stagger: 0.05,
          },
          1.3
        )
        .from(
          ".landing-title:nth-of-type(5) .nameBlue .titleChar",
          {
            y: isMobile ? -80 : -120,
            //y: -120,
            duration: 1,
            ease: "power4.inOut",
            stagger: -0.05,
          },
          0
        )
        .to(
          ".landing-title:nth-of-type(6) .nameBlue .titleChar",
          {
            y: isMobile ? 80 : 120,
            //y: 120,
            duration: 1,
            ease: "power4.inOut",
            stagger: -0.05,
          },
          0
        );
    }, 1500);
  }, []);

  return (
    <>
      <Header />
      <div ref={landingRef} className="landing-container">
        <div className="landing-content">
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed">kevin barbour</span>
              <span className="name nameBlue">kevin barbour</span>
            </span>
          </h1>
          <h1 className="landing-title">
            <span className="names">
              <span className="name nameRed name__end nameEndRed">
                kevin barbour
              </span>
              <span className="name nameBlue name__end nameEndBlue">
                kevin barbour
              </span>
            </span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Landing;
