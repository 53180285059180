import { useState, useEffect } from "react";
import "./Skills.scss";

import Header from "../../components/Header/Header";

const Skills = () => {
  return (
    <>
      <Header />
      <div className="skills-container">
        <div className="skills-title">Skills</div>
      </div>
    </>
  );
};

export default Skills;
